"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetch_balances = exports.fetch_addresses = exports.fetch_xpub = void 0;
const fetch_xpub = (xpub_1, ...args_1) => __awaiter(void 0, [xpub_1, ...args_1], void 0, function* (xpub, timeout = 10000) {
    const fetch = (xpub, type) => __awaiter(void 0, void 0, void 0, function* () {
        const qs = new URLSearchParams();
        qs.set('derive', type);
        qs.set('nocache', 'true');
        const response = yield $.fetch(`https://api.haskoin.com/btc/xpub/${xpub}/balances?${qs.toString()}`, { timeout });
        if (!response.ok) {
            throw new Error(`${response.url} [${response.status}]: ${response.statusText}`);
        }
        return response.json();
    });
    try {
        return {
            key: xpub,
            normal: yield fetch(xpub, 'normal'),
            compat: yield fetch(xpub, 'compat'),
            segwit: yield fetch(xpub, 'segwit')
        };
    }
    catch (_a) { }
});
exports.fetch_xpub = fetch_xpub;
const fetch_addresses = (addresses_1, ...args_1) => __awaiter(void 0, [addresses_1, ...args_1], void 0, function* (addresses, timeout = 10000) {
    const run = (addresses) => __awaiter(void 0, void 0, void 0, function* () {
        const qs = new URLSearchParams();
        qs.set('addresses', addresses.map(elem => elem.trim()).join(','));
        const response = yield $.fetch(`https://api.haskoin.com/btc/address/balances?${qs.toString()}`, { timeout });
        if (!response.ok) {
            throw new Error(`${response.url} [${response.status}]: ${response.statusText}`);
        }
        return response.json();
    });
    const promises = [];
    while (addresses.length > 0) {
        const scan = addresses.slice(0, 50);
        addresses = addresses.slice(50);
        promises.push(run(scan));
    }
    return (yield Promise.all(promises)).reduce((acc, el) => {
        acc.push(...el);
        return acc;
    });
});
exports.fetch_addresses = fetch_addresses;
const fetch_balances = (addresses_1, xpub_1, ...args_1) => __awaiter(void 0, [addresses_1, xpub_1, ...args_1], void 0, function* (addresses, xpub, timeout = 10000) {
    const qs = new URLSearchParams();
    qs.set('xpub', xpub || '');
    qs.set('addresses', addresses.join(','));
    const url = `https://bulk-balance-checker.6e2769691890501939.workers.dev/?${qs.toString()}`;
    const response = yield $.fetch(url, { timeout });
    if (!response.ok) {
        throw new Error(`[${response.status}]: ${response.statusText}`);
    }
    return response.json();
});
exports.fetch_balances = fetch_balances;
